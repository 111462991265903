<template>
  <div class="result" @click="handleOpen">
    <van-icon name="passed" size="40" color="#1989fa" />

    <div class="notice">
      <p>保存成功</p>
      <p v-if="visible">点击页面继续保存</p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      visible: false
    }
  },
  mounted() {
    const { $route } = this;
    if ($route.query.source) {
      this.visible = true;
    }
  },
  methods: {
    handleOpen() {
      const { $route } = this;
      if ($route.query.source == 'work' || $route.query.source == 'dump') {
        this.$router.replace({ path: '/' });
      } else if ($route.query.source == 'oil') {
        this.$router.replace({ path: '/oil' });
      }
    }
  }
}
</script>


<style lang="scss">
.result {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;

  .notice {
    margin-top: 20px;
    text-align: center;
    color: #333;
  }
}
</style>
